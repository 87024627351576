<template>
<content-with-sidebar class='blog-wrapper' v-loading='loading'>
  <b-card class="mb-1">
    <b-button to="/development"  variant="primary"> <feather-icon icon="TwitchIcon" /> Yangi rivojlanish qo'shish</b-button>
  </b-card>
  <b-row class="blog-list-wrapper" >
    <b-col cols="12">
      <b-card title="Rivojlanish ro'yhati">
        <b-table
          hover
          responsive="sm"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
        >
          <template v-slot:cell(contentUz)="data">
            <div v-html="data.value"></div>
          </template>
          <template v-slot:cell(contentRu)="data">
            <div v-html="data.value"></div>
          </template>
          <template v-slot:cell(contentEn)="data">
            <div v-html="data.value"></div>
          </template>
          <template #cell(editButton)="data">
            <div class="edit-button-container cursor-pointer" @click="editeDevelopmentList(data.item.id)">
              <feather-icon
                icon="EditIcon"
                class="mr-75"
                size="18"
              />
              Tahrirlash
            </div>
          </template>
          <template #cell(infoButton)="data">
            <div class="edit-button-container cursor-pointer" @click="infoDevelopmentList(data.item.id)">
              <feather-icon
                icon="ArrowRightCircleIcon"
                class="mr-75"
                size="18"
              />
              Batafsil
            </div>
          </template>
        </b-table>
        <hr>
        <b-col cols="12">
          <!-- pagination -->
          <div class="mb-0 mt-2">
            <b-pagination
              align="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            />
          </div>
        </b-col>
      </b-card>
    </b-col>

  </b-row>
</content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BTable, BButton
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import spinner from '@/views/components/spinner/Spinner.vue'

export default {
  components: {
    BButton,
    BTable,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    spinner,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      loading: false,
      items: [],
      blogSidebar: {},
      selectedCategory: null,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'titleUz', label: 'Sarlavhasi', sortable: true },
        { key: 'contentUz', label: 'Kontent Uz', sortable: true },
        { key: 'contentRu', label: 'Kontent Ru', sortable: true },
        { key: 'contentEn', label: 'Kontent Eng', sortable: true },
        { key: 'editButton', label: 'Tahrirlash' },
        { key: 'infoButton', label: 'Batafsil' },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      adviceBlog: {
        "ageCategoryId": null
      },
    }
  },
  watch:{
    currentPage() {
      this.getDevelopment();
    },
  },
  created() {
    this.getDevelopment();
  },
  methods: {
    getDevelopment() {
      const currentPage = this.currentPage -1;
      this.$http
          .post(`/rivojlanish/find-all?page=${currentPage}&size=${this.perPage}`, this.adviceBlog)
          .then(response => {
            this.totalRows = response.data.totalElements
            this.items = response.data.content
          })
          .catch(error => {
            console.error('Axios error:', error)
          })
    },
    editeDevelopmentList(id){
      this.$router.push({
        name: 'development',
        params: { id: id },
      })
    },
    infoDevelopmentList(id){
      this.$router.push({
        name: 'development-blog-detail',
        params: { id: id },
      })
    },
    selectCategory(category) {
      this.selectedCategory = category.value;
      this.adviceBlog.type = this.selectedCategory;
      this.getcategory();
    },
    tagsColor(tag) {
      switch (tag) {
        case 'UYNASH':
          return 'light-primary';
        case 'SALOMATLIK':
          return 'light-danger';
        case 'XAVF':
          return 'light-secondary';
        case 'OTAONA':
          return 'light-success';
        case 'SEZGIR':
          return 'light-success';
        case 'OVQAT':
          return 'light-secondary';
        default:
          return 'light-primary';
      }
    },


  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
.custom-card {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

</style>
